
import { Component, Vue } from 'vue-property-decorator'
import { initWangEdit } from '@/utils/wangEdit'
import UploadFile from '@/components/uploadFile/Index.vue'
import { Info } from '@/types/diseases'
import { FileInfo, TypeInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'

@Component({
  components: { UploadFile },
  filters: {
    imgListFilter (arr: Array<FileInfo>) {
      return arr.map(item => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class DiseasesAdd extends Vue {
  private typeList: TypeInfo[] = []
  private typeChildList: TypeInfo[] = []
  private monthList = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
  private happenTimeList = []
  private tabList = ['危害及症状', '发生规律', '防治方法', '病原种类', '参考文献']
  private tabIndex = '0'
  private info: Info = {
    diseasesName: '',
    happenArea: '',
    diseasesOtherName: '',
    harmPlants: '',
    diseasesType: '',
    diseasesChildType: '',
    etiologyType: '',
    medicineKind: '',
    harmPart: '',
    happenTime: '',
    resourceList: [],
    symptom: '',
    happenRegular: '',
    preventionMethod: '',
    etiologyKind: '',
    reference: ''
  }

  private rules = {
    diseasesName: [{ required: true, message: '请输入病害名称', trigger: ['blur', 'change'] }],
    diseasesType: [{ required: true, message: '请选择病害类型', trigger: ['change'] }],
    diseasesChildType: [{ required: true, message: '请选择病原类型', trigger: ['change'] }],
    resourceList: [{ required: true, message: '请选择上传病害图片', trigger: ['change'] }],
    symptom: [{ required: true, message: '请输入危害及症状' }],
    happenRegular: [{ required: true, message: '请输入发生规律' }],
    preventionMethod: [{ required: true, message: '请输入防治方法' }]
  }

  private editorSymptom: any = null
  private editorHappenRegular: any = null
  private editorPreventionMethod: any = null
  private editorEtiologyKind: any = null
  private editorReference: any = null
  private submitShow = false
  get diseasesId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getTypeList()
    this.init()
    if (this.diseasesId) {
      this.getDetail()
    }
  }

  destroyed () {
    // 销毁编辑器
    this.editorSymptom.destroy()
    this.editorHappenRegular.destroy()
    this.editorPreventionMethod.destroy()
    this.editorEtiologyKind.destroy()
    this.editorReference.destroy()
    this.editorSymptom = null
    this.editorHappenRegular = null
    this.editorPreventionMethod = null
    this.editorEtiologyKind = null
    this.editorReference = null
  }

  getTypeList () {
    this.$axios.get(this.$apis.common.thinktankTypeList, {
      dicType: 'diseasesType'
    }).then((res) => {
      this.typeList = res.list || []
    })
  }

  // 获取病害类型对应的病原类型
  getChildTypeList (pdicCode: string) {
    this.$axios.get<{total: number; list: TypeInfo[]}>(this.$apis.common.thinktankTypeList, {
      dicType: 'diseasesChildType',
      pdicCode: pdicCode
    }).then((res) => {
      this.typeChildList = res.list || []
    })
  }

  diseasesTypeChange (val: string) {
    this.info.diseasesChildType = ''
    this.typeChildList = []
    if (val) {
      this.getChildTypeList(val)
    }
  }

  // 富文本初始化
  init () {
    this.$nextTick(() => {
      this.editorSymptom = initWangEdit('#symptom', { placeholder: '请输入危害及症状' })
      this.editorSymptom.create()
      this.editorHappenRegular = initWangEdit('#happenRegular', { placeholder: '请输入发生规律' })
      this.editorHappenRegular.create()
      this.editorPreventionMethod = initWangEdit('#preventionMethod', { placeholder: '请输入防治方法' })
      this.editorPreventionMethod.create()
      this.editorEtiologyKind = initWangEdit('#etiologyKind', { placeholder: '请输入病原种类' })
      this.editorEtiologyKind.create()
      this.editorReference = initWangEdit('#reference', { placeholder: '请输入参考文献' })
      this.editorReference.create()
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.diseases.detailDiseases, {
      diseasesId: this.diseasesId
    }).then(res => {
      this.info = res
      if (res.diseasesType) {
        this.getChildTypeList(res.diseasesType)
      }
      this.happenTimeList = res.happenTimeList || []
      this.editorSymptom.txt.html(res.symptom || '')
      this.editorHappenRegular.txt.html(res.happenRegular || '')
      this.editorPreventionMethod.txt.html(res.preventionMethod || '')
      this.editorEtiologyKind.txt.html(res.etiologyKind || '')
      this.editorReference.txt.html(res.reference || '')
    })
  }

  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  onSubmit () {
    // 必填项赋值
    // 获取富文本内容
    this.info.symptom = this.editorSymptom.txt.html()
    this.info.happenRegular = this.editorHappenRegular.txt.html()
    this.info.preventionMethod = this.editorPreventionMethod.txt.html()
    this.tabIndex = this.info.symptom === '' ? '0' : this.info.happenRegular === '' ? '1' : this.info.preventionMethod === '' ? '2' : this.tabIndex
    ;(this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.diseasesId ? this.$apis.diseases.updateDiseases : this.$apis.diseases.addDiseases
        this.info.happenTime = this.happenTimeList.length > 0 ? this.happenTimeList.join(',') : ''
        this.info.etiologyKind = this.editorEtiologyKind.txt.html()
        this.info.reference = this.editorReference.txt.html()

        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'diseasesList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
